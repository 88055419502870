import React, { useState } from "react";
import mobile from "../Images/mobile.png";
import Lock from "../Images/Group.png";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
const SetMpin = (props) => {
    const history =useNavigate()
  const [mpin, setMpin] = useState("");
  const [confirmMpin,setConfirmMpin]=useState("")
  return (
    <div className="cover">
      <div className="tabLanding">
        <div style={{ marginBottom: "20%", marginTop: "15%" }}>
          <img
            src={Lock}
            style={{ width: "80px" }}
            className="mobile"
            alt="PaymeIndia"
          />
        </div>
        <form>
          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              textAlign: "start",
            }}
          >
            <label for="otp">New MPIN</label>

            <OtpInput
              id="otp"
              isInputNum
              value={mpin}
              onChange={(e) => {
                setMpin(e);
              }}
              numInputs={6}
              containerStyle="mpin-input-container "
              inputStyle="mpin-input "
            />
          </div>

          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              textAlign: "start",
            }}
          >
            <label for="otp">Confirm MPIN</label>
            <br />
            <OtpInput
              id="otp"
              isInputNum
              value={confirmMpin}
              onChange={(e) => {
                setConfirmMpin(e);
              }}
              numInputs={6}
              containerStyle="mpin-input-container "
              inputStyle="mpin-input "
            />
          </div>
        </form>
        <br />
        <div style={{ marginTop: "3%" }}>
          <button onClick={()=>{
              history('/pancard')
          }} style={{ width: "264.59px" }} className="signin_btn">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetMpin;
