import React, {useEffect, useState } from "react";
import Logo from "../Images/PayMe-India-logo2.png";
import { Link } from "react-router-dom";
import fram from "../Images/Frame.png";
import OtpVerification from "./OtpVerification";
import mobile from "../Images/mobile.png";
import OtpInput from "react-otp-input";

const NewUser = (props) => {
  const [otpPage, setOtpPage] = useState(false);
  
   const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(59);
  useEffect(() => {

    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      {otpPage ? (
         <div className="cover">
     
        <div className="tabLanding" style={{height:"650px"}}>
          <div>
            <Link to="/">
              {" "}
              <img src={Logo} className="logo" alt="PaymeIndia" />
            </Link>
          </div>

    
          <h4 className="form-heading">
                      OTP Sent On ********04
                    </h4>
          <p>
            00: {counter > 9 ? <span>{counter}</span> : <span>0{counter}</span>}
          </p>
          <br />
          <div>
            <img src={mobile} className="mobile" alt="PaymeIndia" />
          </div>
          <form>
            <div
              style={{
              
                marginLeft: "5%",
                marginRight: "5%",
                textAlign: "start",
              }}
            >
              <label style={{ margin: "9%" }} for="otp">
                Enter OTP
              </label>
              {props.id==="adhar"?(
               <OtpInput
                 id="otp"
                 isInputNum
                 value={otp}
                 onChange={(otp) => {
                 
                   setOtp(otp);
                 }}
                 numInputs={6}
                 containerStyle="mpin-input-container "
                 inputStyle="mpin-input "
               />
             
              ):<OtpInput
                id="otp"
                isInputNum
                value={otp}
                onChange={(otp) => {
        
                  setOtp(otp);
                }}
                numInputs={4}
                shouldAutoFocus={true}
                containerStyle="otp-input-container"
                inputStyle="otp-input"
              />}
              
             
            </div>

           
          </form>
          <div style={{ marginTop: "10%" }}>
            <Link to="/googleauth">  <button  style={{ width: "264.59px" }} className="signin_btn">
                Continue
              </button></Link>
            
            </div>
        </div>
        
      </div>
      ) : (
        <div className="cover">
          <div className="tabLanding" >
            <div>
              <Link to="/">
                {" "}
                <img src={Logo} className="logo" alt="PaymeIndia" />
              </Link>
            </div>

            <br />
            <br />
            <form>
              
              <label className="labelInput" for="phone">
                Enter Your Phone Number
              </label>
              <div style={{ marginTop: "2%" }}>
                <input
                  className="input_phone"
                  name="phone"
                  type="text"
                  id="phone"
                  placeholder="Enter Your Phone Number"
                />
              </div>

              <div style={{ marginTop: "3%" }}>
                <button
                  onClick={(e) => {
                    setOtpPage(true);
                  }}
                  style={{ width: "264.59px" }}
                  className="signin_btn"
                >
                  Continue
                </button>
              </div>
            </form>
            <div>
              <img src={fram} className="framdown" alt="PaymeIndia" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewUser;
