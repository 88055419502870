import React, { useState } from "react";
import Logo from "../Images/PayMe-India-logo2.png";
import { Link } from "react-router-dom";
import fingerPrint from "../Images/fingerprint-scan.png";
import OtpVerification from "./OtpVerification";


const AdharVerification = (props) => {
  const [otpPage, setOtpPage] = useState(false);

  return (
    <>
      {otpPage ? (
        <OtpVerification id="adhar" />
      ) : (
        <div className="cover">
          <div className="tabLanding">
            <div>
              <Link to="/">
                {" "}
                <img src={Logo} className="logo" st alt="PaymeIndia" />
              </Link>
            </div>

            <br />
         
            <div style={{ marginBottom: "40%", marginTop: "5%" }}>
          <img
            style={{ width: "80px" }}
            src={fingerPrint}
            className="PAN"
            alt="PaymeIndia"
          />
        </div>
            <form>
              <label className="labelInput" for="phone">
                Enter Your Adhar Number
              </label>
              <div style={{ marginTop: "2%" }}>
                <input
                  className="input_phone"
                  name="phone"
                  type="text"
                  id="phone"
                  placeholder="Enter Your Adhar Number"
                />
              </div>

              <div style={{ marginTop: "5%" }}>
                <button
                  onClick={(e) => {
                    setOtpPage(true);
                  }}
                  style={{ width: "264.59px" }}
                  className="signin_btn"
                >
                 GET OTP
                </button>
              </div>
            </form>
           
          </div>
        </div>
      )}
    </>
  );
};

export default AdharVerification
