import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/PayMe-India-logo2.png";

const Login = (props) => {
  function x() {
    for (var i = 0; i <= 5; i++) {
      function close(x) {
        setTimeout(function () {
          console.log(x);
        }, x * 1000);
      }
      close(i);
    }
  }
  x();
  if (a !== undefined) {
    console.log("unideisnsn");
  }
  var a = 0;

  return (
    <div className="cover">
      <div className="tabLanding">
        <div style={{ marginBottom: "11%" }}>
          <img src={Logo} className="logo" alt="PaymeIndia" />
        </div>
        <form>
          <label className="labelInput" for="phone">
            Enter Your Phone Number
          </label>
          <div style={{ marginTop: "2%" }}>
            <input
              className="input_phone"
              name="phone"
              type="text"
              id="phone"
              placeholder="Enter Your Phone Number"
            />
          </div>
          <br />
          <label
            style={{ marginLeft: "-33%" }}
            className="labelInput"
            for="phone"
          >
            Enter Your MPIN
          </label>
          <div style={{ marginTop: "2%" }}>
            <input
              className="input_phone"
              name="phone"
              type="text"
              id="phone"
              placeholder="Enter MPIN"
            />
          </div>

          <div style={{ marginTop: "8%" }}>
            <button className="signin_btn">Sign in</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
