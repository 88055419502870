import React from 'react'

const KycComplete = () => {
  return (
    <div style={{textAlign:"center"}}>
      <h1 >WElcome to kyc complete page</h1>
    </div>
  )
}

export default KycComplete;
