import React from "react";
import Logo from "../Images/PayMe-India-logo2.png";
import { Link } from "react-router-dom";
import GoogleFram from "../Images/googlefram.png";
import Google1 from "../Images/google1.png";
import { useNavigate } from 'react-router-dom';
const GoogleAuth = (props) => {
    const history = useNavigate();

  const arr = [1, 2, 2, 3, 3, 4];
  const res = arr.reduce(function (acc, curr) {
    if (!acc.includes(curr)) {
      acc.push(curr);
    }

    return acc;
  }, []);
  console.log(res);
  return (
    <>
      <div className="cover">
        <div className="tabLanding">
          <div>
            <Link to="/">
              {" "}
              <img src={Logo} className="logo" alt="PaymeIndia" />
            </Link>
          </div>
          <div style={{ margin: "5%" }}>
            <img src={GoogleFram} alt="PaymeIndia" />
          </div>

          <div>
            <div className="googleAuth">
              <div className="googleimg">
                {" "}
                <img src={Google1} alt="googleicon" />
              </div>

              <div onClick={()=>{
                  history("/refferal")
              }} className="googletext"> Connect your Gmail</div>
            </div>
          </div>
          <p style={{ cursor: "pointer" ,fontWeight:500}}>Not a Gmail User ?</p>
        </div>
      </div>
    </>
  );
};

export default GoogleAuth;
