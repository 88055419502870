import React from "react";
import mobile from "../Images/mobile.png";
import { useNavigate } from 'react-router-dom';
const Refferal = (props) => {
 const history = useNavigate()

  return (
    <div className="cover">
      <div className="tabLanding">
       
        <div style={{ marginBottom: "20%" ,marginTop: "15%" }}>
            <img src={mobile} className="mobile" alt="PaymeIndia" />
          </div>
        <form>
          <label className="labelInput" style={{marginLeft:"-33%"}} for="phone">
             Phone Number
          </label>
          <div style={{ marginTop: "2%" }}>
            <input
              className="input_phone"
              name="phone"
              type="text"
              id="phone"
              placeholder="Enter Your Phone Number"
            />
          </div>
          <br />
          <label
            style={{ marginLeft: "-36%" }}
            className="labelInput"
            for="phone"
          >
             Refferal Code
          </label>
          <div style={{ marginTop: "2%" }}>
            <input
              className="input_phone"
              name="phone"
              type="text"
              id="phone"
              placeholder="Enter Refferal Code"
            />
          </div>

          <div style={{ marginTop: "8%" }}>
            <button onClick={()=>{
              history("/setmpin")
            }} className="signin_btn">Continue</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Refferal
