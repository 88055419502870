import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./ApplyLoan/Login";
import KycComplete from "./ApplyLoan/KycComplete";
import NewUser from "./ApplyLoan/NewUser";
import LandingPage from "./ApplyLoan/LandingPage";
import Refferal from "./ApplyLoan/Refferal";
import GoogleAuth from "./ApplyLoan/GoogleAuth";
import SetMpin from "./ApplyLoan/SetMpin";
import PanCard from "./ApplyLoan/PanCard";
import AdharVerification from "./ApplyLoan/AdharVerification";

import "./custom.scss"
const App = (props) => {
  return (
    <>
       <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/kyccomplete" element={<KycComplete/>} />
          <Route exact path="/newuser" element={<NewUser/>} />
          <Route exact path="/refferal" element={<Refferal/>} />
          <Route exact path="/googleauth" element={<GoogleAuth/>} />
          <Route exact path="/setmpin" element={<SetMpin/>} />
          <Route exact path="/pancard" element={<PanCard/>} />
          <Route exact path="/adhar-verification" element={<AdharVerification/>} />
       
         
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App




