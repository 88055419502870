import React from "react";
import Idcard from "../Images/idcard.png";
import { useNavigate } from "react-router-dom";
const PanCard = (props) => {
  const history = useNavigate();

  return (
    <div className="cover">
      <div className="tabLanding">
        <div style={{ marginBottom: "20%", marginTop: "15%" }}>
          <img
            style={{ width: "80px" }}
            src={Idcard}
            className="PAN"
            alt="PaymeIndia"
          />
        </div>
        <form>
          <div style={{ marginTop: "2%" }}>
            <label
              className="labelInput"
              style={{ marginLeft: "-45%" }}
              for="phone"
            >
              Your PAN
            </label>

            <br />

            <input
            style={{marginTop:"2%"}}
              className="input_phone"
              name="phone"
              type="text"
              id="phone"
              placeholder="Your PAN Number"
            />
          </div>
          <br />
          <label
            style={{ marginLeft: "-29%" }}
            className="labelInput"
            for="phone"
          >
           Enter Date of birth
          </label>
          <div style={{ marginTop: "2%" }}>
            <input
           
              className="dateinput"
              name="phone"
              type="date"
              id="phone"
              placeholder="Enter Refferal Code"
            />
          </div>

          <div style={{ marginTop: "8%" }}>
            <button
              onClick={() => {
                history("/adhar-verification");
              }}
              className="signin_btn"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PanCard;
