import React from "react";
import Logo from "../Images/PayMe-India-logo2.png";
import { Link } from "react-router-dom";
import fram from "../Images/Frame.png"

const LandingPage = () => {
  const arr =[1,2,2,3,3,4]
const res = arr.reduce(function(acc,curr){
if(!acc.includes(curr)){
 acc.push(curr)
}

return acc;
},[])
  console.log(res)
  return (
    <>
      <div className="cover">
        <div className="tabLanding">
          <div>
           <Link to="/"> <img src={Logo} className="logo" alt="PaymeIndia" /></Link>
          </div>
          <div>
            <img src={fram} className="fram" alt="PaymeIndia" />
          </div>
          <div style={{ marginTop: "25%" }}>
          <Link to="login">
            <button className="signin_btn">Sign in</button>
            </Link>
          </div>

          <div>
            <Link to="newuser">
              <button className="signup_btn">Sign up</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
